import React from "react"
//import Modal from 'react-awesome-modal';

import { Layout, SEO } from "../components"

import styles from "./contact-css-module.module.scss"
import ContactCareer from '../../static/contact_career.svg'
import ContactMedia from '../../static/contact_media.svg'
import ContactEnquiry from '../../static/contact_enquiry.svg'
import ContactMail from '../../static/contact_mail.svg'
import ContactPhone from '../../static/contact_phone.svg'



const Contact = () => {


  return (
    <>
      <Layout>
      <SEO 
      title="Contact Us | Solv - B2B Marketplace for MSME" 
      description="Feel free to contact us for career opportunities, media relations or other enquiries on +91 9108191081. We hope to hear from you soon!"
      />
        <section>
          <div className={styles.contactUs}>
            <div className={styles.container_heading}>We’re here</div>
            <div className={styles.container_subheading}>Our door is always open for a good cup of coffee</div>
          </div>

          <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.office_heading}>
                  <span>Headquarters:</span>
                </div>
                <div className={styles.office_subheading}>
                  <span>Indiqube Edge, Bellandur, Bengaluru Karnataka 560103</span>
                </div>
                <div className={styles.contact_number}>
                  <img src={ContactPhone} /><span>+91 9108191081</span>
                </div>
                <div className={styles.contact_number}>
                  <img src={ContactMail} /><span >cs@solvezy.com</span>
                </div>

                <div className={styles.sales_heading}>
                  <span>Other office:</span>
                </div>
                <div className={styles.office_subheading}>
                  <span>2601, 26th Floor, Tower C, Advant Navis Business Park, Plot #7, Noida-Greater Noida Expy, Sector 142, Noida, Uttar Pradesh 201305</span>
                </div>
                {/* <div className={styles.office_subheading}>
                  <span>Unitech Cyber Park, Sector 39, Gurugram, Haryana 122018</span>
                </div> */}

            </div>

            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d972.1936126308842!2d77.66893112925243!3d12.922213106571247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae137916e3695d%3A0x9d15d6cf4978b67b!2sSolv!5e0!3m2!1sen!2sin!4v1613976853025!5m2!1sen!2sin"
                style={{ border: 0 }}
                className={styles.map_iframe}
                allowFullScreen
                // loading="lazy"
              ></iframe>
            </div>
          </div>
        </section>
        <section>
          <div className={styles.card_container}>
            <div className={styles.contact_card}>
              <div className={styles.card_image_div}>
                <img src={ContactCareer} />
              </div>
              <div>
                <span className={styles.contact_heading}>CAREERS</span>
              </div>
              <div>
                <span className={styles.contact_subheading}>For job opportunities, please contact</span>
              </div>
              <div className={styles.margin_top_5}>
                <span className={styles.card_email}><a href = "mailto: solv.ta@solvezy.com?subject = Job Oppurtunity - Website&body = Type Your Message Here">solv.ta@solvezy.com</a></span>
              </div>
            </div>
            <div className={styles.contact_card}>
              <div className={styles.card_image_div}>
                <img src={ContactMedia} />
              </div>
              <div>
                <span className={styles.contact_heading}>MEDIA RELATIONS</span>
              </div>
              <div>     
                <span className={styles.contact_subheading}>If you are a member of the media or trade press, please contact </span>
              </div>
              <div className={styles.margin_top_5}>
                <span className={styles.card_email}><a href = "mailto: media@solvezy.com">media@solvezy.com</a></span>
              </div>
            </div>
            <div className={styles.contact_card}>
              <div className={styles.card_image_div}>
                <img src={ContactEnquiry} />
              </div>
              <div>
                <span className={styles.contact_heading}>OTHERS</span>
              </div>
              <div>
                <span className={styles.contact_subheading}>For all other enquiries, please contact</span>
              </div>
              <div className={styles.margin_top_5}>
                <span className={styles.card_email}><a href = "mailto: cs@solvezy.com">cs@solvezy.com</a></span>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Contact
